<template>
    <div>
        <CCard class="p-2">
                <form @submit.prevent="submit">
                    <CCardHeader>
                        <CRow style="color: #1352a1;">
                    
                            <CCol lg="7">
                                <h5 v-if="!editMode">New Vendor </h5>
                                <h5 v-else>Update Vendor</h5>
                            </CCol>
                             <CCol lg="4">
                                  <div style="text-align: right">
                                      <CButton size="sm" class="btn btn-ghost-dark" @click="backToTable()"> Cancel</CButton> &nbsp; 
                                      <CButton class="px-3"  size="sm" color="info" type="submit"><i class="fa fa-save"></i> Save</CButton>
                                  </div>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    
                    <CCardBody class="card-form">
                        <CCard class="pl-3 pr-3">
                            <CRow class="mt-3">
                                <CCol lg="12">
                                    <h6 >Vendor Information </h6>
                                    
                                </CCol>
                            </CRow>
                            <br/> 
                            <CRow>
					<CCol lg="12">
						<label class="mb-1">
							Vendor Name <span class="text-danger">*</span>
						</label>
						<CInput
		                  v-model="dataParams.vendor_name"
		                  required="true"
		                />
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="6">
						<label class="mb-1">
							Contact Number <span class="text-danger">*</span>
						</label>
						<CInput
		                  v-model="dataParams.contact_no"
		                  required="true"
						  type="number"
		                />
					</CCol>
					<CCol lg="6">
						<label class="mb-1">
							Email Address<span class="text-danger"> *</span>
						</label>
						<CInput
		                
		                  type="email"
		                 
		                  v-model="dataParams.email"
		                  required="true"
		                />
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="12">
						<label class="mb-1">
							Address 1 <span class="text-danger">*</span>
						</label>
						<CInput
		                 
		                
		                  v-model="dataParams.address_a"
		                  required="true"
		                />
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="12">
						<label class="mb-1">Address 2</label>
						<CInput
		                  v-model="dataParams.address_b" 
		                />
					</CCol>
				</CRow> 
				<CRow>
					<CCol lg="6">
						<label class="mb-1">Owner's Name</label>
						<CInput
		                  v-model="dataParams.owners_name" 
						
		                />
					</CCol>
					<CCol lg="6">
						<label class="mb-1">Owner's Contact No.</label>
						<CInput
		                  v-model="dataParams.owners_contact_no" 
						type="number"
		                />
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="6">
						<label class="mb-1">
							Coordinator's Name <span class="text-danger">*</span>
						</label>
						<CInput
		                  v-model="dataParams.coordinator_name" 
		                  required="true"
		                />
					</CCol>
					<CCol lg="6">
						<label class="mb-1">
							Coordinator's Contact No.<span class="text-danger">*</span>
						</label>
						<CInput
		                  v-model="dataParams.coordinator_contact_no" 
						    type="number"
		                />
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="6">
						<div class="form-group"> 
							<label class="mb-1">
							Vendor Type <span class="text-danger">*</span>
							</label>
							<v-select 
								label="setting_name" 
								:options="vendorTypeList.data"
								:reduce="item => item.id"
								v-model="dataParams.vendor_type_name"
								placeholder="-Select-"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.vendor_type_name"
										v-bind="attributes"
										v-on="events"
                                       
									/>
								</template>
							</v-select>
						</div>  
					</CCol>
					<CCol lg="6">
						<div class="form-group"> 
							<label class="mb-1">
							Tax Type <span class="text-danger">*</span>
						</label>
							<v-select 
								label="label" 
								:options="[
									{
										id: 'VAT',
										label: 'VAT'
									},
									{
										id: 'Non-VAT',
										label: 'Non-VAT'
									}
								]"
								:reduce="item => item.id"
								v-model="dataParams.tax_type"
								placeholder="-Select-"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.tax_type"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div>  
					</CCol>
				</CRow> 
				<CRow>
					<CCol lg="6">
						<div class="form-group">
							<label class="mb-1">Date Started</label>
							<Datepicker 
								input-class="form-control bg-white" 
								v-model="dataParams.date_started"	
							>
							</Datepicker> 
						</div> 
					</CCol>
					<CCol lg="6">
						<div class="form-group">
							<label class="mb-1">Date Separated</label>
							<Datepicker 
								input-class="form-control bg-white" 
								v-model="dataParams.date_seperated"
								
							>
							</Datepicker> 
						</div> 
					</CCol>
				</CRow> 
				<CRow>
					<CCol lg="12">
						<label class="mb-1" >Status
							<span class="text-danger">*</span>
						</label>
						<CInputRadioGroup  
		                    :options="[
		                    	{ 
									value: 'active', 
									label: 'Active'
						        },
						        { 
									value: 'inactive', 
									label: 'Inactive'
						        }
		                    ]" 
		                    :checked="dataParams.status"
		                    @update:checked="updateStatus"
		                    :inline="true" 
		                />
					</CCol>
				</CRow>
                <br>
                                
                        </CCard>    
                    </CCardBody>
                </form>
            </CCard>
        </div>
    </template>
    <script> 
    import config from '../../config.js';
    import axios from '../../axios';
    import Swal from 'sweetalert2';
    import Search from './search'
    import Datepicker from 'vuejs-datepicker';
    import vSelect from 'vue-select'
    import '../../style.css';
    import moment from 'moment';
    
    export default {
        mounted(){  
            this.getVendorType();
            if(this.$route.params.id != undefined){
                this.editMode = true;
            } 
            if(this.$route.params.id){
                this.getData();
            } 
    
        },
        data(){
            return{
                config,
                options:'',
                title:'',
                formModal: false,
                dataParams: {
                    vendor_name: "",
                    contact_no: "",
                    email: "",
                    address_a: "",
                    address_b: "",
                    owners_name: "",
                    owners_contact_no: "",
                    coordinator_name: "",
                    coordinator_contact_no: "",
                    vendor_type_name: "",
                    tax_type: "",
                    status: "active",
                    date_started: "",
                    date_seperated: ""
			},
                editMode: false,
                selectedRow: {},
                vendorTypeList: {
				data: []
			},
                
            }
        },
        name: 'Tables',
        components: {Search, Datepicker, vSelect},
        methods: { 
             backToTable(){
                this.$router.push('/data/vendor')
            },
      
            getVendorType(){

                axios.get(config.api_path+'/reference/settings-list',{
                    params:{
                        setting_type:'vendor_type'
                    }
                        })
                        .then(response => {
                        this.vendorTypeList = response.data; 
                    })

                },
    
            getData(){
                this.$showLoading(true)
    
                axios.get(config.api_path+"/vendor/"+this.$route.params.id)
                .then(response=>{
                    this.$showLoading(false)
                    this.dataParams = response.data.data;

                })
                .catch(err => {
                this.$showLoading(false)
            }) 
    
            }, 
    
            submit(){
	    	var ax = {};
            
            this.dataParams.vendor_type = (typeof this.dataParams.vendor_type_name) == 'object' ?  this.dataParams.vendor_type_name?.id : this.dataParams.vendor_type_name;

	    	if(this.dataParams.date_seperated != ""  && this.dataParams.date_seperated != null){
		    	this.dataParams.date_seperated = moment(this.dataParams.date_seperated).format('YYYY-MM-DD')
		    }

		    if(this.dataParams.date_started != ""  && this.dataParams.date_started != null){
		    	this.dataParams.date_started = moment(this.dataParams.date_started).format('YYYY-MM-DD')
		    }

	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/vendor/"+this.selectedRow.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/vendor", this.dataParams)
	    	}
	    	ax.then(response => {
				const { status } = response.data;

				if(status === 'duplicate') {
					Swal.fire({
					title: 'Vendor name already exists.',
					text,
					icon: 'error', 
				})
					return;
				}
	    		var text = 'Vendor successfully added!';
	    		if(this.editMode){
	    			text = 'Vendor successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					vendor_name: "",
					contact_no: "",
					email: "",
					address_a: "",
					address_b: "",
					owners_name: "",
					owners_contact_no: "",
					coordinator_name: "",
					coordinator_contact_no: "",
					vendor_type: "",
					tax_type: "",
					status: "active"
				}
	    		this.$router.push('/data/view_vendor/'+response.data.data.id)
	    	})
	    },
    
           
            updateVendorType(data){
	    	this.dataParams.vendor_type = data;
	    },
    
            updateStatus(data){
                this.dataParams.status = data;
            },
           
         }
    }
    </script>
    